<template>
    <div class="products-list" v-if="category == 'plast'">
        <box  :border="border" v-for="subcategory in subCategories[0].childs" :key="subcategory.id" class="mb-3">
            <h3 slot="title" class="pb-0 pt-1 px-4">{{ subcategory.name }}</h3>
            <div class="container" slot="body">
                <div class="justify-content-start" v-for="item in subcategory.childs" :key="item.id">
                    <div class="empty"></div>
                    <div class="col">
                      <div class="title-sm px-0 col-lg-3 col-4" :class="border+'-title'">
                        <h6 class="px-2">{{ item.name }}</h6>
                      </div>
                    </div>
                    <div class="row mt-3 justify-content-start">
                        <div class="col-lg-3 col-md-4 col-6" v-for="product in item.products" :key="product.id">
                            <div class="product mb-2">
                                <div class="product-img-splash">
                                    <router-link :to="{name:'Product',params:{language: $i18n.locale, category: $route.params.category,product:product.title}}">
                                      <img class="product-img" :src="'/img/'+product.pic" alt=""><div class="splash"></div>
                                    </router-link>
                                </div>
                                <router-link :to="{name:'Product',params:{language: $i18n.locale, category: $route.params.category,product:product.title}}" class="product-title">{{ product.data.name }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </box>
    </div>
    <div class="product-list" v-else>
      <box  :border="border"  class="mb-3">
            <h3 slot="title" class="pb-0 pt-1 px-4">{{ cat[0].name }}</h3>
            <div class="container" slot="body">
                <div class="justify-content-start" v-for="subcategory in subCategories[0].childs" :key="subcategory.id">
                    <div class="empty"></div>
                    <div class="col">
                      <div class="title-sm px-0 col-lg-3 col-4" :class="border+'-title'">
                        <h6 class="px-2 pt-1">{{ subcategory.name }}</h6>
                      </div>
                    </div>
                    <div class="row mt-3 justify-content-start">
                        <div class="col-lg-3 col-md-4 col-6" v-for="product in subcategory.products" :key="product.id">
                            <div class="product mb-2" v-if="product.data.url != '' ">
                              <a :href="product.data.url">
                                  <div class="image-wrapper">
                                      <img class="product-img" @error="$event.target.src='/img/default.png'" :src="'/img/'+product.pic" alt=""><div class="splash"></div>
                                    <div class="product-title">
                                      <h3>{{ product.data.name }}</h3>
                                    </div>
                                  </div>
                                </a>
                            </div>
                            <div class="product mb-2" v-else>
                              <router-link :to="{name:'Product',params:{language: $i18n.locale, category: $route.params.category,product:product.title}}">
                                  <div class="image-wrapper">
                                      <img class="product-img" @error="$event.target.src='/img/default.png'" :src="'/img/'+product.pic" alt=""><div class="splash"></div>
                                    <div class="product-title">
                                      <h3>{{ product.data.name }}</h3>
                                    </div>
                                  </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </box>
    </div>
</template>

<script>
import Box from './Box.vue'
import httpClient from '@/services/http.service';
    export default {
        components: { Box },
        name:'ProductsList',
        props:{
            border:String,
            category:String,
        },
        data(){
          return {
            subCategories:new Array(),
            errors : null,
            cat:null,
          };
        },
        async mounted() {
          if (this.category == 'plast') {
            var expend ='expand=childs,childs.childs,childs.childs.products';  
          }else{
             expend = 'expand=childs,childs.products';
          }
          try {
            const {status , data} = await httpClient.get('/fa/categories',{params:{
              title:this.category,
              expand:expend,
            }})
            if (status == 200) {
              this.subCategories = data;
            }
          } catch (e) {
            this.errors = 'Conection Is Faild';
          }
          try {
            const {status , data} = await httpClient.get('/fa/categories',{params:{
              title:this.category,
            }})
            if (status == 200) {
              this.cat = data;
            }
          } catch (e) {
            this.errors = 'Conection Is Faild2';
          }
        }
    }
</script>

<style lang="scss" scoped>
    .products-list{
        min-height: 1200px;
    }
    .title-sm{
        color: #fff;
        font-size: 15px;
        width: max-content;
        min-width: 100px;
    }
    .product{
  background: #fff;
  margin-bottom:10px;
  text-align: center;
  position: relative;
}
.product-title{
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:1;
  color: white;
  font-size: 20px;
  padding: 5px;
  text-align: center;
}
.product-title:hover{
  color:rgba(40, 40, 110, 0.774);
}

.image-wrapper{
  width: 100%;
  overflow: hidden;
}
.product-img{
  display: block;
  width: 100%;
  height: auto;
  
}
a:hover{
  text-decoration: none;
}
.product-title h3{
  font-weight: bolder;
  font-size: 20px;
}
</style>
